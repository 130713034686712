footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background: white;
  border-top: 1px solid rgb(233, 233, 233);
}

.footerSection {
  padding-left: 40px;
  padding-right: 40px;
  width: min(1100px, 100%);
  margin: auto;
  box-sizing: border-box;
}

.footerMenu {
  font-weight: 600;
  font-size: 15px;
  width: min(600px, 100%);
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footerMenu div {
  margin-bottom: 10px;
}

.footerBuisness {
  color: #909090;
  font-size: 14px;
  line-height: 26px;
}

.footerBuisness span {
  color: #e4e4e4;
  margin: 3px;
}

.menuTitle {
  color: #121212;
  font-weight: 700;
}

.menuSub {
  color: #909090;
  cursor: pointer;
  font-weight: 500;
  transition: color 200ms;
}

.menuSub:hover {
  color: #121212;
  transition: color 200ms;
}

.logoFooter {
  margin-left: -9px;
  margin-bottom: -16px;
}

.createdBy {
  float: right;
}

@media (max-width: 560px) {
  .footerSection {
    padding-left: 14px;
    padding-right: 14px;
  }
}
