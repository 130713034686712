.PriceComponent {
  margin: auto;
  width: min(100%, 1100px);
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  color: #121212;
  padding-top: 100px;
  padding-bottom: 140px;
  /* background-color: rgb(255, 252, 244); */
}

.priceItems {
  display: flex;
  justify-content: space-between;
}

.priceItem {
  width: 30.8%;
  aspect-ratio: 1 / 1.4;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
  background-color: rgb(255, 255, 255);
  transition: scale 200ms;
  box-sizing: border-box;
  overflow: hidden;
}

.priceItem:hover {
  scale: 1.02;
  transition: scale 200ms;
}

.PriceComponent .title {
  font-size: 48px;
  text-align: center;
  margin: auto;
  width: 100%;
  margin-bottom: 50px;
  font-weight: 700;
  display: inline-block;
  color: #121212;
  line-height: 66px;
}

.PriceComponent .subTitle {
  font-size: 22px;
  color: #ff4747;
  margin-bottom: -10px;
}

.PriceComponent .desc {
  font-size: 18px;
  color: #888888;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28px;
}

.PriceComponent .activeFrame {
  box-shadow: rgba(255, 0, 0, 0.68) 0px 0px 20px 1px;
}

.PriceComponent .priceTitle {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 8px;
}

.PriceComponent .priceImage {
  height: 200px;
  /* replace as image */
  background-color: rgb(185, 185, 185);
}

.PriceComponent .priceContent {
  padding: 28px;
}

.PriceComponent .priceSubTitle {
  font-size: 14px;
  color: #888888;
  margin-bottom: 16px;
}

.PriceComponent .priceDesc {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
