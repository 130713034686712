.DiscountTimer {
  position: fixed;
  bottom: 30px;
  left: 0px;
  width: 100%;
  animation: slideInFromBottom 1.1s ease-out forwards;
  animation-delay: 200ms;
  transform: translateY(100px);
  z-index: 3000;
  opacity: 1;
}

.HidingDiscountTimer {
  position: fixed;
  bottom: -140px;
  left: 0px;
  width: 100%;
  transition: 1.1s;
  animation: FadeOut 0.4s ease-in-out forwards;
  transition-timing-function: ease-in;
  z-index: 3000;
  opacity: 0;
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(45px);
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.timerFrame {
  width: 580px;
  height: 50px;
  margin: auto;
  border-radius: 14px;
  background-color: #ffffffd8;
  font-weight: 700;
  font-size: 20px;
  padding: 10px 20px;
  padding-right: 12px;
  backdrop-filter: blur(4px);
  box-shadow: rgba(18, 18, 27, 0.2) 0px 7px 29px 0px;
}

.text {
  margin-top: 13px;
}

.text img {
  width: 24px;
  margin-bottom: -4px;
  margin-right: 2px;
  margin-left: 4px;
}

.timer {
  margin-top: -10px;
  float: right;
  background-color: #161616;
  color: white;
  width: 250px;
  height: 36px;
  text-align: center;
  padding-top: 10px;
  border-radius: 10px;
}

.hidingTimer {
  margin-top: -30px;
}

.closeBtn {
  float: right;
  width: 40px;
  margin-top: -6px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .DiscountTimer {
    position: fixed;
    bottom: 30px;
    left: 0px;
    width: 100%;
    animation: slideInFromBottom 1.8s ease-out forwards;
    animation-delay: -800ms;
    transform: translateY(100px);
    z-index: 3000;
  }

  .HidingDiscountTimer {
    position: fixed;
    bottom: -140px;
    left: 0px;
    width: 100%;
    transition: 1s;
    transition-timing-function: ease-in-out;
    z-index: 3000;
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(180px);
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .timerFrame {
    /* padding-top: 30px; */
    width: 400px;
    height: 104px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .text {
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
  }

  .text img {
    width: 24px;
    margin-bottom: -4px;
    margin-right: 2px;
    margin-left: 4px;
  }

  .timer {
    font-size: 20px;
    margin: auto;
    margin-top: 12px;
    float: none;
    width: 280px;
  }

  .closeBtn {
    width: 30px;
    margin-top: -10px;
    margin-right: -1px;
    margin-left: -18px;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .DiscountTimer {
    position: fixed;
    bottom: 30px;
    left: 0px;
    width: 100%;
    animation: slideInFromBottom 1.8s ease-out forwards;
    animation-delay: -800ms;
    transform: translateY(100px);
    z-index: 3000;
  }

  .HidingDiscountTimer {
    position: fixed;
    bottom: -140px;
    left: 0px;
    width: 100%;
    transition: 1s;
    transition-timing-function: ease-in-out;
    z-index: 3000;
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(180px);
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .timerFrame {
    /* padding-top: 30px; */
    width: 300px;
    height: 104px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .text {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
  }

  .text img {
    width: 24px;
    margin-bottom: -4px;
    margin-right: 2px;
    margin-left: 4px;
  }

  .timer {
    font-size: 20px;
    margin: auto;
    margin-top: 12px;
    float: none;
    width: 280px;
  }

  .closeBtn {
    width: 30px;
    margin-top: -10px;
    margin-right: -1px;
    margin-left: -18px;
    cursor: pointer;
  }
}
