.StartNowComponent {
  margin: auto;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  color: white;
  padding-top: 60px;
  padding-bottom: 60px;
  background: #121212;
  /* background-image: url("../assets/start-bg-now.svg");
  background-size: auto; */
}

.StartNowComponent .title {
  font-weight: 700;
  font-size: 48px;
  text-align: center;
}

.StartNowComponent #offer {
  margin: auto;
  margin-top: 50px;
  width: 180px;
  height: 48px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  transition: color 200ms, background-color 200ms, box-shadow 200ms;
  background-color: white;
  color: #121212;
  box-shadow: 0 0 0 2px white;
  display: block;
}

.StartNowComponent #offer:hover {
  background-color: transparent;
  color: white;
  transition: color 200ms, background-color 200ms;
}

.StartNowComponent .titleFrame {
  width: 100%;
  /* height: 100px; */
  overflow: hidden;
}

.StartNowComponent .descFrame {
  width: 100%;
  /* height: 100px; */
  overflow: hidden;
}

.StartNowComponent .desc {
  font-size: 18px;
  color: #ffffffd2;
  font-weight: 500;
  margin-top: 10px;
  line-height: 28px;
  text-align: center;
}
