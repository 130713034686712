header {
  background-color: transparent;
  font-weight: 500;
  display: unset;
}

.st01 {
  transition: fill 300ms;
}

.topbar {
  width: min(1100px, 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  height: 60px;
  padding-right: 40px;
  padding-left: 40px;
  box-sizing: border-box;
}

#adDiv {
  background-color: #121212;
}

.openOffer {
  box-sizing: border-box;
  cursor: pointer;
  height: 50px;
  padding-top: 14px;
  width: 180px;
  text-align: center;
  margin-right: -7px;
}

#adComment {
  font-size: 20px;
  font-weight: 600;
  cursor: default;
}

.openOffer #adGoTo {
  font-size: 17px;
}

.openOffer span {
  margin-right: 0px;
  transition: margin-right 200ms ease-in-out;
}

.openOffer:hover span {
  margin-right: 6px;
  transition: margin-right 200ms ease-in-out;
}

.openOffer:hover span span {
  color: white;
  transition: 200ms ease-in-out;
}

.openOffer span span {
  transition: 200ms ease-in-out;
}

#adGoTo span {
  font-weight: 700;
}

.topAd {
  color: white;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: min(1100px, 100%);
  margin: auto;
  align-items: center;
}

.scrolledStickyDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 9000;
}

.stickyDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9000;
  background-color: white;
  border-bottom: 1px solid rgb(233, 233, 233);
  box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
}

#topbarDiv,
#scrolledTopbarDiv {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

#signIn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100px;
  height: 34px;
  font-size: 16px;
  font-weight: 600;
  color: #121212;
}

#signUp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 34px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  transition: color 200ms, background-color 200ms, box-shadow 200ms;
  background-color: #121212;
  color: white;
  box-shadow: 0 0 0 2px #121212;
}

#signUp:hover {
  background-color: transparent;
  color: #121212;
  transition: color 200ms, background-color 200ms;
}

.btnScrollToTop {
  background-color: transparent;
  width: 144px;
  padding: 0px;
  margin-left: -8px;
}

.statusBg {
  display: none;
}

@media (max-width: 560px) {
  #adDiv {
    display: none;
  }
}

@media (max-width: 480px) {
  .topbar {
    padding-right: 14px;
    padding-left: 14px;
  }

  #signUp {
    font-size: 14px;
    width: 80px;
    height: 34px;
  }

  #signIn {
    display: none;
    font-size: 14px;
    width: 80px;
    height: 32px;
  }

  #topbarDiv,
  #scrolledTopbarDiv {
    width: 80px;
  }

  #adDiv {
    display: none;
  }

  .statusBg {
    display: block;
    position: fixed;
    background-color: #161616;
    width: 100vw;
    height: 500px;
    margin-top: -500px;
  }
}
