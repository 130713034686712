@keyframes textAppear {
  0% {
    transform: translateY(120%);
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

video {
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 40px 15px;
}

.mainVideo {
  margin-top: 120px;
  margin-bottom: 50px;
}

.MainContainer {
  background-color: white;
  margin-top: -60px;
}

.dim {
  position: fixed;
  width: 100%;
  height: 1000%;
  background-color: black;
  pointer-events: none;
  opacity: 1;
  animation: dim 1.4s ease-in-out forwards;
  transform: translateY(-50px);
  z-index: 9999;
}

.introduce {
  background-size: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-image: url(../assets/background.png);
  background-size: cover;
}

.introduceContent {
  position: relative;
  z-index: 0;
  padding-top: 80px;
  width: min(1100px, 100%);
  margin: auto;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}

.introduce span {
  display: block;
  font-size: 70px;
}

.introduceTitle {
  margin-bottom: 10px;
  font-weight: 700;
}

.introduceDesc span {
  margin-top: 20px;
  font-size: 24px;
}

.delay0 {
  transform: translateY(120%);
  animation: textAppear 2.4s ease-in-out forwards;
  animation-delay: -1200ms;
  opacity: 0;
}

.delay1 {
  transform: translateY(120%);
  animation: textAppear 2.4s ease-in-out forwards;
  animation-delay: -1050ms;
  opacity: 0;
}

.delay2 {
  transform: translateY(120%);
  animation: textAppear 2.4s ease-in-out forwards;
  animation-delay: -900ms;
  margin-bottom: 6px;
  opacity: 0;
}

.delay3 {
  transform: translateY(120%);
  animation: textAppear 2.4s ease-in-out forwards;
  animation-delay: -750ms;
  margin-bottom: 6px;
  opacity: 0;
}

.laurel {
  width: 160px;
  margin: auto;
  margin-bottom: 30px;
}

.stars {
  width: 100px;
  left: calc(50% - 50px);
  transform: translateY(-93px);
  position: absolute;
}

.laurelTopText {
  font-size: 14px;
  width: 100px;
  position: absolute;
  font-weight: 700;
  left: calc(50% - 50px);
  transform: translateY(12px);
}

.laurelBottomText {
  font-size: 10px;
  width: 100px;
  position: absolute;
  font-weight: 700;
  left: calc(50% - 50px);
  transform: translateY(-22px);
}

@media (max-width: 768px) {
  .introduceTitle span {
    font-size: 50px;
  }

  .introduceDesc span {
    font-size: 17px;
  }

  .mainVideo {
    margin-top: 140px;
  }
}

@media (max-width: 480px) {
  .introduceContent {
    padding-top: 20lvh;
    padding-right: 14px;
    padding-left: 14px;
  }

  .introduceTitle span {
    display: block;
    font-size: 10vw;
  }

  .introduceDesc span {
    font-size: 3.4vw;
  }

  .mainVideo {
    margin-top: 100px;
  }
}
