.BenefitsComponent {
  width: 100% !important;
  background-color: #f8f8f8;
}

.BenefitsContent {
  margin: auto;
  width: min(100%, 1100px);
  padding: 100px 40px;
  box-sizing: border-box;
  color: #121212;
}

.character {
  width: 90%;
  margin-top: 50px;
}

.logo {
  display: block;
  margin-left: -12px;
}

.BenefitsComponent .subTitle {
  font-size: 22px;
  color: #ff4747;
  margin-bottom: -10px;
  margin-top: -22px;
}

.BenefitsComponent .desc {
  font-size: 18px;
  color: #888888;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28px;
}

.BenefitsComponent .title {
  font-size: 48px;
  font-weight: 700;
  display: inline-block;
  color: #121212;
  line-height: 66px;
  margin-bottom: 20px;
}

.title span {
  color: #121212;
}

.stickyContent {
  top: 26lvh;
  position: sticky;
  z-index: 2000;
  width: 500px;
  align-self: flex-start;
  margin-bottom: 28px;
  float: left;
}

.scrollContent {
  width: 468px;
  font-size: 24px;
  color: #121212;
  align-self: flex-end;
  line-height: 40px;
  font-weight: 500;
  margin-left: 500px;
  padding-left: 52px;
  border-left: 1px solid rgb(238, 238, 238);
}

.icon {
  transform: translateY(3px);
  margin-right: 8px;
}

.benefitItem {
  width: 100%;
  height: 260px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 52px;
  box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
  aspect-ratio: 1/ 1.5;
}

.frames {
  overflow: hidden;
}

@media (max-width: 768px) {
  .logo {
    width: 140px;
    margin-left: -7px;
  }

  .title {
    font-size: 29px;
  }
}

@media (max-width: 480px) {
  .WhyEveryPageComponent {
    padding-right: 14px;
    padding-left: 14px;
  }

  .logo {
    width: 140px;
    margin-left: -7px;
  }

  .title {
    font-size: 20px;
  }

  /*temp*/
  .benefitItem {
    font-size: 14px;
  }
}
