.PortfolioComponent {
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  color: #121212;
  background-color: white;
}

.portfolioContent {
  margin: auto;
  width: min(100%, 1100px);
  padding: 100px 40px 125px 40px;
  box-sizing: border-box;
}

.PortfolioComponent .title {
  font-size: 48px;
  text-align: center;
  margin: auto;
  width: 100%;
  margin-bottom: 50px;
  font-weight: 700;
  display: inline-block;
  color: #121212;
  line-height: 66px;
}

.PortfolioComponent .subTitle {
  font-size: 22px;
  color: #1188ff;
  margin-bottom: -10px;
}

.PortfolioComponent .desc {
  font-size: 18px;
  color: #888888;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28px;
}

.PortfolioComponent .gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.PortfolioComponent .galleryItem {
  aspect-ratio: 1 / 0.8;
  flex-basis: 28%;
  flex-shrink: 1;
  flex-grow: 1;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
  margin: 2%;
}

@media (max-width: 768px) {
  .PortfolioComponent .galleryItem {
    aspect-ratio: 1 / 0.8;
    flex-basis: 40%;
    flex-shrink: 1;
    flex-grow: 1;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
  }
}

@media (max-width: 480px) {
  .PortfolioComponent .galleryItem {
    aspect-ratio: 1 / 0.8;
    flex-basis: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
  }
}
