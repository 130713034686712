.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #121212;
  transform-origin: 0%;
  z-index: 9000;
}
