.QuestionComponent {
  margin: auto;
  width: 100%;
  background-color: #f8f8f8;
  overflow: hidden;
  position: relative;
}

.QuestionContent {
  width: min(100%, 900px);
  margin: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  color: #121212;
}

.QuestionContent .title {
  font-size: 48px;
  margin: auto;
  width: 100%;
  margin-bottom: 50px;
  font-weight: 700;
  display: inline-block;
  color: #121212;
  line-height: 66px;
  text-align: center;
}

.QuestionContent .subTitle {
  font-size: 22px;
  color: #1188ff;
  margin-bottom: -10px;
  text-align: center;
}

.QuestionContent .icon {
  margin: auto;
  margin-top: -42px;
  position: absolute;
  transform: translateX(-680px);
  opacity: 0.02;
  z-index: 0;
}

.talkFrame {
  margin: auto;
  width: min(100%, 1100px);
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 50px 50px 30px 50px;
  box-sizing: border-box;
  line-height: 28px;
  z-index: 1000;
  position: relative;
  box-shadow: rgba(145, 145, 171, 0.1) 0px 0px 30px 4px;
}

.question {
  background-color: white;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 0 24px 24px 24px;
  box-sizing: border-box;
  font-weight: 500;
  padding: 15px 25px;
  position: relative;
  width: max-content;
  max-width: min(500px, 100%);
}

.answer {
  background-color: #1188ff;
  color: white;
  margin-left: auto;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 24px 0 24px 24px;
  box-sizing: border-box;
  font-weight: 500;
  padding: 15px 25px;
  position: relative;
  width: max-content;
  max-width: min(500px, 100%);
}
