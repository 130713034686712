.ProcessComponent {
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  color: #121212;
  background-color: #f8f8f8;
}

.processContent {
  margin: auto;
  width: min(100%, 1100px);
  padding: 100px 40px 130px 40px;
  box-sizing: border-box;
}

.ProcessComponent .title {
  font-size: 48px;
  text-align: center;
  margin: auto;
  width: 100%;
  font-weight: 700;
  display: inline-block;
  color: #121212;
  line-height: 66px;
  margin-bottom: 50px;
}

.ProcessComponent .subTitle {
  font-size: 22px;
  color: #09cb5a;
  margin-bottom: -10px;
}

.ProcessComponent .desc {
  font-size: 18px;
  color: #888888;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28px;
}

.ProcessComponent .processItem {
  margin: auto;
  width: min(1100px, 100%);
  height: 150px;
  margin-top: 50px;
}

.ProcessComponent .itemIndexLeft {
  width: 120px;
  height: 120px;
  margin: 15px;
  border-radius: 100%;
  background-color: #09cb5a;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  padding-top: 32px;
  box-sizing: border-box;
  float: left;
  position: relative;
}

.ProcessComponent .itemIndexRight {
  width: 120px;
  height: 120px;
  margin: 15px;
  border-radius: 100%;
  background-color: #09cb5a;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  padding-top: 32px;
  box-sizing: border-box;
  float: right;
  position: relative;
}

.ProcessComponent .itemDescLeft {
  width: 335px;
  height: 150px;
  margin-right: 100px;
  float: left;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
}

.ProcessComponent .itemDescRight {
  width: 335px;
  height: 150px;
  margin-left: 100px;
  float: Right;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.08) 0px 0px 15px 1px;
}

.ProcessComponent .centerLine {
  margin-top: -185px;
  height: 200px;
  border-left: 1px solid #09cb5a;
  border-right: 1px solid #09cb5a;
  position: absolute;
  left: 50%;
}

.ProcessComponent .horizontalLine {
  position: absolute;
  width: 115px;
  border-top: 4px dotted rgb(215, 215, 215);
  left: 335px;
  margin-top: 73px;
}

.ProcessComponent .verticalLine {
  position: absolute;
  width: 115px;
  border-top: 4px dotted rgb(215, 215, 215);
  margin-top: 73px;
  right: 335px;
}
